export default {
  colors: {
    background: '#ffffff',
    title: '#121212',
    text: '#121212',
    primary: '#121212',
    secondary: '#e9e9e9',
    tertiary: '#f3f3f3',
    highlight: '#515d6d',

    representation: '#0BC3A7',
    personnes_concernees: '#FFB400',
    feel_good: '#2E629E',
    militant: '#D83A36',
    deconstruction: '#EB7138',
  },

  fonts: {
    body:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
    siteName: 'Montserrat Alternates, Roboto, Helvetica, Arial, sans-serif',
    titles: 'Montserrat, Roboto, Helvetica, Arial, sans-serif',
  },
  sizes: {
    maxWidth: '1050px',
    maxWidthCentered: '650px',
  },
  responsive: {
    small: '35rem',
    medium: '50rem',
    large: '70rem',
  },
}
